<template>
  <div>
    <b-overlay :show="loading">
      <div class="row" style="min-height: 250px">
        <div class="col-6 col-xs-6 col-md-3" v-for="product in productList" :key="product._id">
          <single-product :data="product"></single-product>
        </div>
        <slot></slot>
      </div>
    </b-overlay>
  </div>
</template>

<script>
//import singleProduct from "../components/singleProduct";
const singleProduct = () => import(/* webpackChunkName: "singleproduct" */"../components/singleProduct.vue");

export default {
  components: {
    singleProduct,
  },
  props: ["id", "params"],
  data() {
    return {
      products: [],
      loading: true,
      limit: 4,
    };
  },
  mounted() {
    console.log("Mounted", this.params);
    if (this.params) {
      let params = this.params.split(",");
      console.log({params})
      params.forEach((element) => {
        let [key, value] = element.split("=");
        console.log(this[key])
        if (this[key]) this[key] = value;
      });
    }
    this.load();
  },
  methods: {
    load() {
      let { webName } = this.$store.state.info;

      this.$http
        .get(`${webName}/product/mostselled`)
        .then((res) => {
          this.products = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    productList() {
      return this.products.slice(0, this.limit);
    }
  }
};
</script>